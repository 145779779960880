import { useContext, useEffect, useMemo, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { PortfolioReconciliationContext } from "../context/PortfolioReconciliationContext"
import { FidcService } from "../services/FidcService"
import { PortfolioReconciliationService } from "../services/PortfolioReconciliationService"
import { useAccessToken, useSupabase } from "./useSupabase"

export const usePortfolioReconciliation = () => {
  const { getAccessToken } = useAccessToken()
  const [dates, setDates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [portfolioTable, setPortfolioTable] = useState(null)
  const [previousReconciliation, setPreviousReconciliation] = useState(null)
  const [extractTable, setExtractTable] = useState(null)

  const supabase = useSupabase()

  const { portfolio, extract, valuesToIdentify, date, setFidc, setDate, clear } = useContext(PortfolioReconciliationContext)
  const { toast } = useContext(GlobalContext)

  const fidcService = useMemo(() => FidcService(supabase), [supabase])
  const portfolioReconciliationService = useMemo(() => PortfolioReconciliationService(supabase), [supabase])

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      setDates(await portfolioReconciliationService.getReconciliationDatesResume(supabase))
      setIsLoading(false)
    }

    fetch()
  }, [supabase, portfolioReconciliationService])

  const reloadPage = () => window.location.reload()

  const insertInDatabase = async () => {
    setIsLoading(true)

    try {
      await portfolioReconciliationService.insertPortfolioReconciliation(
        portfolioTable.map((row) => ({
          fund: row.fund,
          data: row.data,
          tipo: row.tipo,
          description: row.description,
          quant: row.quant,
          PU: row.PU,
          value: row.value,
          id_fidc: row.id_fidc,
        })),
        extractTable.map((row) => ({
          fund: row.fund,
          data: row.data,
          account: row.account,
          description: row.description,
          balance: row.balance,
          debit: row.debit,
          credit: row.credit,
        })),
      )

      const shortName = await fidcService.getFidcShortName(portfolioTable[0].id_fidc)
      await portfolioReconciliationService.saveFilesInDataLake(portfolio, extract, valuesToIdentify, shortName, date, await getAccessToken())
    } finally {
      setIsLoading(false)
      reloadPage()
      clear()
    }
  }

  const submit = async () => {
    if (!portfolio || !extract || !dates) return

    setIsLoading(true)

    try {
      const payload = await portfolioReconciliationService.sendFilesToAnalyze(portfolio, extract, null, dates, await getAccessToken())
      setPortfolioTable(payload.result)
      setExtractTable(payload.extract)
      setFidc(payload.fidc)
      setDate(payload.date)
      setPreviousReconciliation(payload.previous)
    } catch (error) {
      toast.error({ message: error.response.data.error })
      clear()
    } finally {
      setIsLoading(false)
    }
  }

  const getLoadingMessage = () => {
    if (!dates.length) return "Consultando últimas conciliações"
    else if (!portfolioTable || !extractTable) return "Realizando batimento"
    else return "Subindo batimento para o banco de dados"
  }

  return { portfolioTable, extractTable, previousReconciliation, dates, isLoading, insertInDatabase, reloadPage, submit, getLoadingMessage }
}
