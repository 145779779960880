import axios from "axios"
import { TABLES } from "../utils/supabase"

export const URL = `${process.env.REACT_APP_RECONCILIATION_SERVICE}/history`

export const HistoryReconciliationService = (supabase) => {
  const getAvailableDates = async (idFidc) => {
    const { data } = await supabase.from(TABLES.VIEW_DATES_RECONCILIATED).select("*").eq("id_fidc", idFidc, "type", "carteira")
    return data.map((d) => d.date)
  }

  const getHistory = async (idFidc, date, token) => {
    const { data } = await axios.get(URL, {
      params: { id_fidc: idFidc, date },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }

  const coldStart = async (token) => {
    try {
      await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { id_fidc: 2, date: "2024-11-05" },
      })
    } catch {
      return
    }
  }

  return { getAvailableDates, getHistory, coldStart, URL }
}
