import { createContext, useState } from "react"

export const HistoryReconciliationContext = createContext({
  idFidc: null,
  setIdFidc: () => {},
  date: null,
  setDate: () => {},
})

export const HistoryReconciliationProvider = ({ children }) => {
  const [idFidc, setIdFidc] = useState(null)
  const [date, setDate] = useState(null)

  return <HistoryReconciliationContext.Provider value={{ idFidc, setIdFidc, date, setDate }}>{children}</HistoryReconciliationContext.Provider>
}
