import { HistoryReconciliationProvider } from "../../context/HistoryReconciliationContext"
import { GenericHome } from "../organisms/GenericHome"
import { HistoryReconciliation } from "../templates/HistoryReconciliation"

export const ConsorcieiHome = () => {
  const menuItems = [
    {
      key: "1",
      label: "Histórico de Carteiras",
      children: (
        <HistoryReconciliationProvider>
          <HistoryReconciliation />
        </HistoryReconciliationProvider>
      ),
    },
  ]

  return <GenericHome menuItems={menuItems} />
}
