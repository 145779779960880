import { HistoryReconciliationService } from "../services/HistoryReconciliationService"
import { PortfolioReconciliationService } from "../services/PortfolioReconciliationService"

export const useLambda = () => {
  const coldStartServices = async (accessToken) => {
    try {
      await Promise.all([HistoryReconciliationService().coldStart(accessToken), PortfolioReconciliationService().coldStart(accessToken)])
    } catch {
      return
    }
  }

  return { coldStartServices }
}
