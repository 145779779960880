import { createClient } from "@supabase/supabase-js"
import { ConfigProvider } from "antd"
import useNotification from "antd/es/notification/useNotification"
import { useEffect, useState } from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { Loader } from "./components/atoms/Loader"
import { ConsorcieiHome } from "./components/pages/ConsorcieiHome"
import { ConsorcieiLogin } from "./components/pages/ConsorcieiLogin"
import { MainLogin } from "./components/pages/MainLogin"
import { TeraHome } from "./components/pages/TeraHome"
import { PrivateRoute } from "./components/PrivateRoute"
import { GlobalContext } from "./context/GlobalContext"
import { useLambda } from "./hooks/useLambda"
import { colors } from "./style/colors"
import { PROFILES } from "./utils/utils"

export const App = () => {
  const [supabase, setSupabase] = useState(null)
  const [session, setSession] = useState(null)
  const [profile, setProfile] = useState(null)
  const [api, contextHolder] = useNotification()
  const navigate = useNavigate()

  const { coldStartServices } = useLambda(session)

  const loadSession = (sessionLoaded) => {
    if (!sessionLoaded) return

    setSession(sessionLoaded)

    if (sessionLoaded.user.email.includes("@teracapital.com.br")) setProfile(PROFILES.TERA)
    if (sessionLoaded.user.email.includes("@consorciei.com.br")) setProfile(PROFILES.CONSORCIEI)
  }

  useEffect(() => {
    if (!supabase) setSupabase(createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY))
  }, [supabase])

  useEffect(() => {
    if (profile) navigate(`/${profile}`)
  }, [profile, navigate])

  useEffect(() => {
    if (!supabase) return () => {}

    supabase.auth.getSession().then(({ data: { session } }) => loadSession(session))

    const { data } = supabase.auth.onAuthStateChange((_, session) => loadSession(session))

    return () => data.subscription.unsubscribe()
  }, [supabase])

  useEffect(() => {
    if (session?.access_token) coldStartServices(session.access_token)
  }, [session, coldStartServices])

  if (!supabase) return <Loader />

  const DefaultRoute = () => {
    if (window.location.href.includes("access_token")) return <Loader />
    if (profile) return <Navigate to={`/${profile}`} replace />
    return <Navigate to="/login" replace />
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: profile === PROFILES.TERA ? colors.primary : colors.consorcieiPrimary,
        },
      }}
    >
      <GlobalContext.Provider value={{ supabase, session, toast: api, profile }}>
        {contextHolder}
        <Routes>
          <Route path="/login" element={<MainLogin />} />
          <Route path={`/login/${PROFILES.CONSORCIEI}`} element={<ConsorcieiLogin />} />
          <Route
            path={`${PROFILES.TERA}`}
            element={
              <PrivateRoute profileAllowed={PROFILES.TERA}>
                <TeraHome />
              </PrivateRoute>
            }
          />
          <Route
            path={`${PROFILES.CONSORCIEI}`}
            element={
              <PrivateRoute profileAllowed={PROFILES.CONSORCIEI}>
                <ConsorcieiHome />
              </PrivateRoute>
            }
          />
          <Route path="*" element={DefaultRoute()} />
        </Routes>
      </GlobalContext.Provider>
    </ConfigProvider>
  )
}

export default App
