import { Collapse, Table } from "antd"
import { formatToReal } from "../../utils/utils"

export const ReconciliationCollapse = ({ previousReconciliation, portfolioTable, extractTable }) => {
  const columnsPortfolioReconciliation = [
    {
      title: "Fundo",
      dataIndex: "fund",
      key: "fund",
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      width: "100px",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantidade",
      dataIndex: "quant",
      key: "quant",
    },
    {
      title: "PU",
      dataIndex: "PU",
      key: "PU",
      render: formatToReal,
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: formatToReal,
    },
  ]

  const comparationRender = {
    render: (value) => {
      if (value > 0) return <span style={{ color: "green" }}>{formatToReal(value)}</span>
      if (value < 0) return <span style={{ color: "red" }}>{formatToReal(value)}</span>
      return "N/A"
    },
  }

  const columnsToCompare = [
    ...columnsPortfolioReconciliation,
    {
      title: "Diferença",
      dataIndex: "difference",
      key: "difference",
      ...comparationRender,
    },
    {
      title: "Esperado",
      dataIndex: "expected",
      key: "expected",
      ...comparationRender,
    },
  ]

  const extractColumns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Conta",
      dataIndex: "account",
      key: "account",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Crédito",
      dataIndex: "credit",
      key: "credit",
      render: formatToReal,
    },
    {
      title: "Débito",
      dataIndex: "debit",
      key: "debit",
      render: formatToReal,
    },
    {
      title: "Saldo",
      dataIndex: "balance",
      key: "balance",
      render: formatToReal,
    },
  ]

  const collapseItems = []

  if (previousReconciliation) {
    collapseItems.push({
      key: "previousReconciliation",
      label: <b>{`Carteira anterior - ${previousReconciliation?.[0].data}`}</b>,
      children: <Table dataSource={previousReconciliation} pagination={false} columns={columnsPortfolioReconciliation} size="small" />,
    })
  }

  if (portfolioTable) {
    collapseItems.push({
      key: "portfolioToReconciliate",
      label: <b>{`Carteira em análise - ${portfolioTable?.[0].data}`}</b>,
      children: <Table dataSource={portfolioTable} pagination={false} columns={columnsToCompare} size="small" />,
    })
  }

  if (extractTable) {
    collapseItems.push({
      key: "extractReconciliation",
      label: <b>Extrato</b>,
      children: <Table dataSource={extractTable} pagination={false} columns={extractColumns} size="small" />,
    })
  }

  if (!collapseItems.length) return <></>

  return <Collapse items={collapseItems} style={{ width: "100%" }} defaultActiveKey={["portfolioToReconciliate"]} />
}
