import { TABLES } from "../utils/supabase"

export const FidcService = (supabase) => {
  const fidcTable = supabase.from(TABLES.FIDC)
  const trancheTable = supabase.from(TABLES.TRANCHE)

  const getFidcs = async () => {
    const { data, error } = await fidcTable.select("id, short_name")
    if (error) throw error
    return data
  }

  const getFidcShortName = async (id) => {
    const { data, error } = await fidcTable.select("short_name").eq("id", id)
    if (error) throw error
    return data[0].short_name
  }

  const getTranches = async (fidc) => {
    const { data, error } = await trancheTable.select("id, name").eq("id_fidc", fidc)
    if (error) throw error
    return data
  }

  return { getFidcs, getFidcShortName, getTranches }
}
