import { Button, Col, Flex, Input, Row, Select } from "antd"
import { useState } from "react"
import { FidcSelect } from "../atoms/FidcSelect"
import { Loader } from "../atoms/Loader"
import { useAmortization } from "../../hooks/useAmortization"
import { spaces } from "../../style/spaces"
import { useFidc } from "../../hooks/useFidc"

export const Amortizations = () => {
  const [fidc, setFidc] = useState(null)
  const [tranche, setTranche] = useState(null)
  const [puJuros, setPuJuros] = useState(null)
  const [puPrincipal, setPuPrincipal] = useState(null)
  const [puAmortizacao, setPuAmortizacao] = useState(null)
  const [quantidade, setQuantidade] = useState(null)
  const [valor, setValor] = useState(null)

  const { isLoading } = useAmortization({ idFidc: fidc })
  const { tranches } = useFidc()

  if (isLoading)
    return (
      <Flex>
        <Loader />
      </Flex>
    )

  return (
    <Flex style={{ width: "100%", justifyContent: "center", marginTop: spaces.space2 }}>
      <Flex vertical style={{ width: 500, gap: spaces.space1 }}>
        <Row gutter={16}>
          <Col span={24}>
            FIDC: <FidcSelect setValue={setFidc} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            Tranche:{" "}
            <Select style={{ width: "100%" }} value={tranche} onChange={setTranche} disabled={!fidc}>
              {tranches.map((tranche) => (
                <Select.Option key={tranche.id} value={tranche.id}>
                  {tranche.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            PU (Juros): <Input value={puJuros} onChange={(e) => setPuJuros(e.target.value)} disabled={!tranche} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            PU (Principal): <Input value={puPrincipal} onChange={(e) => setPuPrincipal(e.target.value)} disabled={!puJuros} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            PU (Amortização): <Input value={puAmortizacao} onChange={(e) => setPuAmortizacao(e.target.value)} disabled />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            Quantidade: <Input value={quantidade} onChange={(e) => setQuantidade(e.target.value)} disabled={!puPrincipal} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            Valor: <Input value={valor} onChange={(e) => setValor(e.target.value)} disabled />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Button type="primary" style={{ marginTop: spaces.space2 }}>
              Registrar nova amortização
            </Button>
          </Col>
        </Row>
      </Flex>
    </Flex>
  )
}
