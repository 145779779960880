import { DatePicker, Flex } from "antd"
import dayjs from "dayjs"
import { useContext } from "react"
import { HistoryReconciliationContext } from "../../context/HistoryReconciliationContext"
import { useHistoryReconciliation } from "../../hooks/useHistoryReconciliation"
import { spaces } from "../../style/spaces"
import { FidcSelect } from "../atoms/FidcSelect"
import { Loader } from "../atoms/Loader"
import { ReconciliationCollapse } from "../molecules/ReconciliationCollapse"

export const HistoryReconciliation = () => {
  const { history, datesAvailable, previous, isLoading } = useHistoryReconciliation()
  const { idFidc, setIdFidc, date, setDate } = useContext(HistoryReconciliationContext)

  const isAllowedDate = (current) => {
    return datesAvailable.map((date) => dayjs(date)).some((date) => date.isSame(current, "day"))
  }

  if (isLoading)
    return (
      <Flex vertical style={{ width: "max", height: "30vh", justifyContent: "center", gap: spaces.space1 }}>
        <Loader />
        <h3 style={{ textAlign: "center" }}>Carregando...</h3>
      </Flex>
    )

  return (
    <Flex vertical style={{ padding: spaces.space2 }} gap={spaces.space2}>
      <Flex gap={spaces.space1}>
        <FidcSelect setValue={setIdFidc} value={idFidc} />
        <DatePicker
          disabled={!idFidc}
          disabledDate={(date) => !isAllowedDate(date)}
          value={date ? dayjs(date) : null}
          onChange={(e) => setDate(e.format("YYYY-MM-DD"))}
          allowClear={false}
          format={"DD/MM/YYYY"}
          placeholder="Selecione a data"
        />
      </Flex>
      <ReconciliationCollapse portfolioTable={history} previousReconciliation={previous} />
    </Flex>
  )
}
