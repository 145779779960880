import { useEffect, useState } from "react"
import { FidcService } from "../services/FidcService"
import { useSupabase } from "./useSupabase"

export const useFidc = () => {
  const supabase = useSupabase()
  const [fidcs, setFidcs] = useState([])
  const [tranches] = useState([])

  const service = FidcService(supabase)

  useEffect(() => {
    service.getFidcs().then(setFidcs)
  }, [service])

  return { fidcs, tranches }
}
