import { useContext, useEffect, useState } from "react"
import { HistoryReconciliationContext } from "../context/HistoryReconciliationContext"
import { HistoryReconciliationService } from "../services/HistoryReconciliationService"
import { useAccessToken, useSupabase } from "./useSupabase"

export const useHistoryReconciliation = () => {
  const supabase = useSupabase()
  const { getAccessToken } = useAccessToken()
  const [history, setHistory] = useState(null)
  const [previous, setPrevious] = useState(null)
  const [datesAvailable, setDatesAvailable] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { date, setDate, idFidc } = useContext(HistoryReconciliationContext)

  const service = HistoryReconciliationService(supabase)

  useEffect(() => {
    if (!date || !idFidc) return () => {}

    const fetch = async () => {
      setIsLoading(true)
      const { portfolio: history, previous } = await service.getHistory(idFidc, date, await getAccessToken())
      setHistory(history)
      setPrevious(previous)
      setIsLoading(false)
    }

    fetch()
  }, [date, idFidc, getAccessToken, service])

  useEffect(() => {
    if (!idFidc) return () => {}
    setDate(null)
    service.getAvailableDates(idFidc).then(setDatesAvailable)
  }, [idFidc, service, setDate])

  return { history, previous, datesAvailable, isLoading, setHistory }
}
