import * as XLSX from "xlsx"

export const formatNumber = (number) => new Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 2 }).format(number)

export const excelSerialDateToJSDate = (serial) => {
  const excelEpoch = new Date(Date.UTC(1900, 0, 1))
  const jsDate = new Date(excelEpoch.getTime() + (serial - 2) * 24 * 60 * 60 * 1000)

  const year = jsDate.getUTCFullYear()
  const month = String(jsDate.getUTCMonth() + 1).padStart(2, "0")
  const day = String(jsDate.getUTCDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

export const convertDateStringtoYYYYMMDD = (dateString) => {
  const [datePart] = dateString.split(" ")
  const [day, month, year] = datePart.split("/")
  return `${year}-${month}-${day}`
}

export const getExcelContentFromFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: "array" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      resolve(worksheet)
    }
  })

export const getExcelContent = (event) =>
  new Promise((resolve) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: "array" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      resolve(worksheet)
    }
  })

export const removeDuplicateSpaces = (str) => {
  return str.replace(/\s+/g, " ").trim()
}

export const mapHeaders = (headerRow) => {
  const headerMap = {}
  headerRow.forEach((header, index) => {
    headerMap[header] = index
  })
  return headerMap
}

export const findHeaderRow = (sheet, headerValue) => {
  for (let i = 0; i < sheet.length; i++) if (sheet[i].includes(headerValue)) return i
  return null
}

export const formatToReal = (value) => new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value)

export const reloadPage = () => window.location.reload()

export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const PROFILES = {
  TERA: "tera",
  CONSORCIEI: "consorciei",
}
