import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "../context/GlobalContext"

export const PrivateRoute = ({ children, profileAllowed }) => {
  const { profile, session } = useContext(GlobalContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!session || profile !== profileAllowed) navigate("/login")
  }, [session, profile, navigate, profileAllowed])

  return children
}
