export const callSupabaseFunction = async (supabase, functionName, params) => {
  const { data, error } = await supabase.rpc(functionName, params)

  if (error) {
    console.error("Error calling function:", error)
    throw error
  }

  return data
}

export const FIDC_IDS = {
  "FIDC CONSORCIOS YELLOWSTONE - RESPONSABILIDADE LIMITADA": 1,
  "FIDC CONSORCIEI II": 2,
  "": 3,
}

export const TABLES = {
  FIDC: "fidc",
  FIDC_FEE: "fidc_fee",
  BATIMENTO_CARTEIRAS_FIDC: "batimento_carteiras_fidc",
  BATIMENTO_EXTRATOS_FIDC: "batimento_extratos_fidc",
  VIEW_RECONCILIATION_DATES_RESUME: "view_reconciliation_dates_resume",
  TRANCHE: "tranche",
  VIEW_DATES_RECONCILIATED: "view_dates_reconciliated",
}

export const COLUMNS = {
  DATE_COLUMN: "data",
  QUANTITY: "quant",
  PU: "PU",
  VALUE: "value",
  DATE: "Data",
  DIFFERENCE: "Difference",
  TYPE: "tipo",
}
