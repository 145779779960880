import { Layout } from "antd"
import { useContext, useState } from "react"
import ConsorcieiLogo from "../../assets/consorciei-transparent.png"
import { GlobalContext } from "../../context/GlobalContext"
import { colors } from "../../style/colors"
import { spaces } from "../../style/spaces"
import { PROFILES } from "../../utils/utils"
import { Logo } from "../atoms/Logo"
import { HeaderMenu } from "../molecules/HeaderMenu"

export const GenericHome = ({ menuItems }) => {
  const [menuKey, setMenuKey] = useState("1")
  const HEADER_HEIGHT = 64
  const { profile } = useContext(GlobalContext)

  return (
    <Layout style={{ height: "auto", minHeight: "100vh" }}>
      <Layout.Header
        style={{
          backgroundColor: colors.menuBackgroundColor,
          display: "flex",
          alignItems: "center",
          paddingRight: spaces.space5,
          paddingLeft: spaces.space5,
          position: "sticky",
          top: 0,
          zIndex: 1,
          height: HEADER_HEIGHT,
          borderBottom: `1px solid ${colors.menuBorderColor}`,
          gap: spaces.space2,
        }}
      >
        <Logo width={105} height={30} />
        {profile === PROFILES.CONSORCIEI && <img src={ConsorcieiLogo} alt="Consorciei Logo" style={{ width: 90, margin: 0 }} />}
        <HeaderMenu
          setMenuKey={setMenuKey}
          menuItems={menuItems.map((item) => ({
            key: item.key,
            label: item.label,
          }))}
        />
      </Layout.Header>
      <Layout.Content>{menuItems.find((item) => item.key === menuKey)?.children}</Layout.Content>
    </Layout>
  )
}
