import { useContext } from "react"
import { GlobalContext } from "../context/GlobalContext"

export const useSupabase = () => {
  const { supabase } = useContext(GlobalContext)
  return supabase
}

export const useAccessToken = () => {
  const supabase = useSupabase()

  const getAccessToken = async () => {
    if (!supabase) return null
    const { data } = await supabase.auth?.refreshSession()
    return data?.session?.access_token
  }

  return { getAccessToken }
}
