import axios from "axios"
import { portfolioReconciliationSample } from "../utils/coldStartFixtures"
import { TABLES } from "../utils/supabase"
import { convertFileToBase64 } from "../utils/utils"

export const PortfolioReconciliationService = (supabase) => {
  const getPortfolioReconciliated = async (date) => {
    const { data, error } = await supabase.from(TABLES.BATIMENTO_CARTEIRAS_FIDC).select("*").eq("data", date)
    if (error) throw error
    return data
  }

  const getReconciliationDatesResume = async () => {
    const { data, error } = await supabase.from(TABLES.VIEW_RECONCILIATION_DATES_RESUME).select("*")
    if (error) throw error
    return data
  }

  const insertPortfolioReconciliation = async (portfolio, extract) => {
    const { error } = await supabase.from(TABLES.BATIMENTO_CARTEIRAS_FIDC).insert(portfolio)
    if (error) throw error

    const { error: errorExtract } = await supabase.from(TABLES.BATIMENTO_EXTRATOS_FIDC).insert(extract)
    if (errorExtract) throw errorExtract
  }

  const URL_RECONCILIATION = `${process.env.REACT_APP_RECONCILIATION_SERVICE}/portfolio-reconciliation/sync`
  const URL_SAVE_RAW_FILE = `${process.env.REACT_APP_RECONCILIATION_SERVICE}/save-raw-file`

  const sendFilesToAnalyze = async (portfolio, extract, valuesToIdentify, dates, token) => {
    const data = { portfolio: await convertFileToBase64(portfolio), extract: await convertFileToBase64(extract), dates }

    if (valuesToIdentify) data.values_to_identify = await convertFileToBase64(valuesToIdentify)

    const { data: response } = await axios.post(URL_RECONCILIATION, data, { headers: { Authorization: `Bearer ${token}` } })

    return response
  }

  const saveFilesInDataLake = async (portfolio, extract, valuesToIdentify, fidcShortName, date, token) => {
    const data = {
      portfolio: await convertFileToBase64(portfolio),
      extract: await convertFileToBase64(extract),
      date,
      fidc_short_name: fidcShortName,
    }
    if (valuesToIdentify) data.values_to_identify = await convertFileToBase64(valuesToIdentify)
    await axios.post(URL_SAVE_RAW_FILE, data, { headers: { Authorization: `Bearer ${token}` } })
  }

  const coldStart = async (token) => {
    await axios.post(URL_RECONCILIATION, portfolioReconciliationSample, { headers: { Authorization: `Bearer ${token}` } })
  }

  return {
    getPortfolioReconciliated,
    getReconciliationDatesResume,
    insertPortfolioReconciliation,
    sendFilesToAnalyze,
    saveFilesInDataLake,
    coldStart,
  }
}
