import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from "@supabase/auth-ui-shared"
import { Flex } from "antd"
import { useContext } from "react"
import Banner from "../../assets/consorciei-banner.png"
import { GlobalContext } from "../../context/GlobalContext"
import { colors } from "../../style/colors"
import { spaces } from "../../style/spaces"

export const ConsorcieiLogin = () => {
  const { supabase } = useContext(GlobalContext)
  return (
    <Flex
      vertical
      style={{
        height: "100vh",
        backgroundColor: "rgb(52, 52, 52)",
        alignItems: "center",
        gap: spaces.space2,
      }}
    >
      <Flex vertical style={{ padding: spaces.space2, alignItems: "center" }}>
        <img src={Banner} alt="Consorciei Logo" width={400} style={{ margin: 0 }} />
        <Flex>
          <Auth
            supabaseClient={supabase}
            localization={{
              variables: {
                sign_in: {
                  email_label: "E-mail",
                  email_input_placeholder: "Digite seu e-mail",
                  password_label: "Senha",
                  password_input_placeholder: "Digite sua senha",
                  button_label: "Entrar",
                },
              },
            }}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    brand: colors.consorcieiPrimary,
                    brandAccent: colors.consorcieiPrimary,
                  },
                },
              },
            }}
            theme="dark"
            providers={[]}
            style={{ width: 500 }}
            showLinks={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
