import { Select } from "antd"
import { useFidc } from "../../hooks/useFidc"

export const FidcSelect = ({ setValue, value }) => {
  const { fidcs } = useFidc()

  return (
    <Select placeholder="Selecione o FIDC" onChange={setValue} style={{ width: 200 }} value={value}>
      {fidcs.map((fidc) => (
        <Select.Option key={fidc.id} value={fidc.id}>
          {fidc.short_name}
        </Select.Option>
      ))}
    </Select>
  )
}
