import { CheckOutlined, WarningOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Space, Statistic } from "antd"
import { usePortfolioReconciliation } from "../../hooks/usePortfolioReconciliation"
import { spaces } from "../../style/spaces"
import { Loader } from "../atoms/Loader"
import { ReconciliationCollapse } from "../molecules/ReconciliationCollapse"
import { PortfolioUpload } from "../organisms/PortfolioUpload"

export const PortfolioReconciliation = () => {
  const { dates, portfolioTable, extractTable, previousReconciliation, isLoading, insertInDatabase, reloadPage, submit, getLoadingMessage } =
    usePortfolioReconciliation()

  if (isLoading)
    return (
      <Flex vertical style={{ width: "max", height: "30vh", justifyContent: "center", gap: spaces.space1 }}>
        <Loader />
        <h3 style={{ textAlign: "center" }}>{getLoadingMessage()}...</h3>
      </Flex>
    )

  return (
    <>
      <Flex vertical style={{ padding: spaces.space2, height: "100%" }}>
        <Flex gap={spaces.space1}>
          {dates.map((date, index) => (
            <Card title={date.fund} size="small" styles={{ body: { display: "flex", flexDirection: "column", alignItems: "flex-end" } }} key={index}>
              <Statistic
                title={<span style={{ fontSize: "16px" }}>Último batimento:</span>}
                value={date.last_reconciliation}
                valueStyle={{ color: "green", display: "inline-block", marginLeft: "10px", fontSize: "16px", fontWeight: 500 }}
                prefix={<CheckOutlined style={{ color: "green", fontSize: "16px" }} />}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              />
              <Statistic
                title={<span style={{ fontSize: "16px" }}>Pendente:</span>}
                value={date.next_reconciliation}
                valueStyle={{ color: "red", display: "inline-block", marginLeft: "10px", fontSize: "16px", fontWeight: 500 }}
                prefix={<WarningOutlined style={{ color: "red", fontSize: "16px" }} />}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              />
            </Card>
          ))}
        </Flex>
        <Space style={{ flex: 1, width: "100%", marginTop: spaces.space2, justifyContent: "center" }}>
          {!portfolioTable || !extractTable ? (
            <PortfolioUpload onSubmit={submit} />
          ) : (
            <Flex vertical>
              <ReconciliationCollapse previousReconciliation={previousReconciliation} portfolioTable={portfolioTable} extractTable={extractTable} />
              <Space style={{ gap: spaces.space1, marginTop: spaces.space2 }}>
                <Button type="primary" onClick={insertInDatabase}>
                  Subir batimento para o banco de dados
                </Button>
                <Button onClick={reloadPage}>Limpar</Button>
              </Space>
            </Flex>
          )}
        </Space>
      </Flex>
    </>
  )
}
