import { WindowsFilled } from "@ant-design/icons"
import { Button, Flex } from "antd"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import BackgroundImage from "../../assets/background-login.png"
import LogoConsorciei from "../../assets/consorciei-logo.png"
import { GlobalContext } from "../../context/GlobalContext"
import { colors } from "../../style/colors"
import { spaces } from "../../style/spaces"
import { Logo } from "../atoms/Logo"

export const MainLogin = () => {
  const { supabase } = useContext(GlobalContext)
  const navigate = useNavigate()

  const handleAzureLogin = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "azure",
      options: {
        scopes: "email",
        redirectTo: window.location.origin,
      },
    })

    if (error) console.error("Error logging in with Azure:", error.message)
  }

  return (
    <Flex
      gap={spaces.space5}
      style={{
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(" + BackgroundImage + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      vertical
    >
      <Logo width={300} src={"https://iwluwlzcpkxhdjrcanka.supabase.co/storage/v1/object/public/assets/tera-logo-branco.png"} />
      <Flex vertical gap={spaces.space1}>
        <Button
          onClick={handleAzureLogin}
          icon={<WindowsFilled style={{ fontSize: 24 }} />}
          style={{ width: 300, height: 50, backgroundColor: colors.primary, color: "white", border: "none" }}
        >
          Acesso TERA
        </Button>
        <Button
          onClick={() => navigate("/login/consorciei")}
          icon={<img src={LogoConsorciei} alt="Consorciei Logo" style={{ width: 30, height: 30 }} />}
          style={{ width: 300, height: 50, backgroundColor: colors.consorcieiPrimary, color: "white", border: "none" }}
        >
          Acesso Consorciei
        </Button>
      </Flex>
    </Flex>
  )
}
