import { HistoryReconciliationProvider } from "../../context/HistoryReconciliationContext"
import { PortfolioReconciliationProvider } from "../../context/PortfolioReconciliationContext"
import { Amortizations } from "../templates/Amortizations"
import { HistoryReconciliation } from "../templates/HistoryReconciliation"
import { PortfolioReconciliation } from "../templates/PortfolioReconciliation"
import { GenericHome } from "../organisms/GenericHome"

export const TeraHome = () => {
  const items = [
    {
      key: "1",
      label: "Batimento de Carteiras",
      children: (
        <PortfolioReconciliationProvider>
          <PortfolioReconciliation />
        </PortfolioReconciliationProvider>
      ),
    },
    {
      key: "2",
      label: "Histórico de Carteiras",
      children: (
        <HistoryReconciliationProvider>
          <HistoryReconciliation />
        </HistoryReconciliationProvider>
      ),
    },
    {
      key: "3",
      label: "Amortizações",
      children: <Amortizations />,
    },
  ]

  return <GenericHome menuItems={items} />
}
