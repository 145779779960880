import React from "react"
import ReactDOM from "react-dom/client"
import { HashRouter as Router } from "react-router-dom"
import App from "./App"
import "./style/global.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  // <ConfigProvider theme={{ token: designSystem }}>
  <Router>
    <App />
  </Router>,
  // </ConfigProvider>,
)
